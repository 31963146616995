// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-template-blog-list-blog-list-tsx": () => import("./../src/template/blogList/blog-list.tsx" /* webpackChunkName: "component---src-template-blog-list-blog-list-tsx" */),
  "component---src-template-category-blog-category-tsx": () => import("./../src/template/category/blog-category.tsx" /* webpackChunkName: "component---src-template-category-blog-category-tsx" */),
  "component---src-template-blog-blog-post-tsx": () => import("./../src/template/blog/blog-post.tsx" /* webpackChunkName: "component---src-template-blog-blog-post-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

